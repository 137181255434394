//import { anchorLinkScroll, isInViewport, fadeIn } from './modules/helpers.js';
import Popup from './modules/popup-window.js';

// Take some useful functions
import {
	closest_polyfill,
	debounce,
	anchorLinkScroll,
} from './modules/helpers.js';

// Tabs functionality (uncomment it if you need it)
import { tabsNavigation } from './modules/navi-tabs';


/**
 * All custom code is wrapped in IIFE function
 * to prevent affect our code to another parts of code
 */
;(function ( $ ) {

	/**
	 * PLease Collect here all variables with DOM elements
	 * Use const for all DOM elements and type it as UPPERCASE text
	 * It will help to each developer understand that it's a const not a variable
	 */


	const popupInstance = new Popup();
	popupInstance.init();

	const openPhone = document.querySelectorAll( '.js-open-phone' );
	const openCatalog = document.querySelector( '.js-open-catalog' );
	const bannerSlider = document.querySelector( '.js-banner-slider' );
	const categoriesSwiper = document.querySelector( '.js-categories-swiper' );
	const productCategorySwiper = document.querySelector( '.js-product-category-swiper' );
	const recommendedProductSwiper = document.querySelector( '.js-recommended-product-swiper' );
	const videoReviewsSlider = document.querySelector( '.js-video-reviews-slider' );
	const openFilter = document.querySelectorAll( '.js-open-filter' );
	const allProperties = document.querySelectorAll( '.js-show-all-properties' );
	const archiveForm = document.querySelector( '.js-archive-form' );
	const saleFilterForm = document.querySelector( '.js-sale-filter-form' );
	const sortLink = document.querySelectorAll( '.js-sort-link' );
	const sortSaleLink = document.querySelectorAll( '.js-sort-sale-link' );
	const saleFilter = document.querySelectorAll( '.js-sale-filter-item' );
	const salePagination = document.querySelector( '.js-archive-sale-pagination' );
	const sliderProductFor = document.querySelector( '.js-single-product-for' );
	const sliderProductNav = document.querySelector( '.js-single-product-nav' );
	const starLinks = document.querySelectorAll( '.js-star-rating' );
	const ratingForm = document.querySelector( '.js-rating-form' );
	const showCommentForm = document.querySelector( '.js-show-comment-form' );
	const hideForm = document.querySelectorAll( '.js-hide-form' );
	const adminAnswerForm = document.querySelectorAll( '.js-admin-answer' );
	const openAnswerForm = document.querySelectorAll( '.js-open-answer' );
	const loadReview = document.querySelector( '.js-load-review' );
	const reviewLinks = document.querySelectorAll( '.js-review-link' );
	const addQuestion = document.querySelector( '.js-add-question' );
	const questionForm = document.querySelector( '.js-question-form' );
	const questionAnswerForm = document.querySelector( '.js-admin-question-answer' );
	const openQuestionAnswer = document.querySelectorAll( '.js-open-question-answer' );
	const loadQuestion = document.querySelector( '.js-load-question' );
	const closeCard = document.querySelector( '.js-close-card' );
	const cardPopup = document.querySelector( '.js-card-popup' );
	const openCard = document.querySelectorAll( '.js-open-card' );
	const cardCount = document.querySelector( '.js-card-count' );
	const loginForm = document.querySelector( '.js-login-form' );
	const registerNavLinks = document.querySelectorAll( '.js-register-nav' );
	const acceptCheckbox = document.querySelectorAll( '.js-accept' );
	const registerForm = document.querySelector( '.js-register-form' );
	const logout = document.querySelectorAll( '.js-logout' );
	const returnProductForm = document.querySelector( '.js-return-product-form' );
	const telMask = document.querySelectorAll( '.js-tel-mask' );
	const setSertificate = document.querySelector( '.js-set-sertificate' );
	const checkoutFirstStep = document.querySelector( '.js-checkout-first-step' );
	const checkoutForm = document.querySelector( '.js-checkout-form' );
	const closeThankYou = document.querySelector( '.js-close-thank-you' );
	const sortOrder = document.querySelector( '.js-account-sort-order' );
	const checkAllWish = document.querySelector( '.js-check-all-wish' );
	const cardChecked = document.querySelectorAll( '.js-card-checked' );
	const deleteWish = document.querySelector( '.js-delete-wish' );
	const buyAllWish = document.querySelector( '.js-buy-all' );
	const comparisonTabLink = document.querySelectorAll( '.js-сomparison-tab-link' );
	const deleteComparison = document.querySelector( '.js-delete-сomparison' );
	const loadMyReviews = document.querySelector( '.js-load-my-reviews' );
	const loadMyQuestion = document.querySelector( '.js-load-my-question' );
	const myReviewsTabLink = document.querySelectorAll( '.js-my-reviews-tab-link' );
	const personalDataForm = document.querySelector( '.js-personal-data-change' );
	const changeUser = document.querySelector( '.js-change-user' );
	const personalDataChange = document.querySelector( '.js-personal-data-change' );
	const personalDataList = document.querySelector( '.js-personal-data-list' );
	const personalDataCancel = document.querySelector( '.js-personal-data-cancel' );
	const searchInput = document.querySelector( '.js-search-input' );
	const closeSaleFilter = document.querySelector( '.js-close-sale-filter' );
	const saleFilterWrap = document.querySelector( '.js-sale-filter-wrap' );
	const showSalesFilter = document.querySelector( '.js-show-sales-filter' );
	const archiveMobileSorting = document.querySelector( '.js-archive-mobile-sorting' );
	const archiveTermFilter = document.querySelector( '.js-archive-product-term-filter' );
	const closeTermFilter = document.querySelector( '.js-close-term-filter' );
	const openArchiveFilter = document.querySelector( '.js-open-archive-filter' );
	const applyFilter = document.querySelectorAll( '.js-apply-filter' );
	const singleProductAnchors = document.querySelectorAll( '.js-single-product-anchor' );
	const closePersonalMenu = document.querySelector( '.js-close-personal-account-menu' );
	const personalAccountMenu = document.querySelector( '.js-personal-account-menu' );
	const openPersonalAccountMenu = document.querySelector( '.js-open-personal-account-menu' );
	const saleSortingSelect = document.querySelector('.js-sale-sorting-mobile');
	const closeMobileMenu = document.querySelector('.js-close-mobile-menu');
	const mobileMenu = document.querySelector('.js-mobile-menu');
	const openMobileMenu = document.querySelector('.js-open-mobile-menu');
	const openMobileCatalog = document.querySelector('.js-open-mobile-catalog');
	const mobileCatalogList = document.querySelector('.js-mobile-catalog-list');
	const body = document.querySelector('body');

	openMobileCatalog && openMobileCatalog.addEventListener('click', event => {
		if(!mobileCatalogList) return;
		$(mobileCatalogList).slideToggle();
	})

	openMobileMenu && openMobileMenu.addEventListener('click', event => {
		event.preventDefault();
		if(!mobileMenu) return;
		mobileMenu.classList.add('open');
		body.classList.add('menu-open');
	})

	closeMobileMenu && closeMobileMenu.addEventListener('click', event => {
		event.preventDefault();
		if(!mobileMenu) return;
		mobileMenu.classList.remove('open');
		body.classList.remove('menu-open');
	})

	saleSortingSelect && saleSortingSelect.addEventListener('change', event => {
		event.preventDefault();
		if ( !saleFilterForm ) return;
		saleFormFunction( saleFilterForm, 0 );
	})

	openPersonalAccountMenu && openPersonalAccountMenu.addEventListener('click', event => {
		event.preventDefault();
		if ( personalAccountMenu ) {
			personalAccountMenu.classList.add('open');
		}
	})

	closePersonalMenu && closePersonalMenu.addEventListener('click', event => {
		event.preventDefault();
		if ( personalAccountMenu ) {
			personalAccountMenu.classList.remove('open');
		}
	})

	if(singleProductAnchors) {
		anchorLinkScroll('.js-single-product-anchor');
	}

	document.addEventListener('DOMContentLoaded', function() {
		const anchorBlock = document.querySelector('.js-single-product-anchors');
		if (!anchorBlock) return;

		const originalTop = anchorBlock.offsetTop;

		window.addEventListener('scroll', function() {

			const currentScroll = document.documentElement.scrollTop;

			if (currentScroll >= originalTop) {
				anchorBlock.classList.add('fixed');
			} else {
				anchorBlock.classList.remove('fixed');
			}
		});
	});

	applyFilter && applyFilter.forEach( item => {
		item.addEventListener('click', event => {
			event.preventDefault();

			if(!archiveTermFilter) return;

			archiveTermFilter.classList.remove('open');
		})
	})

	openArchiveFilter && openArchiveFilter.addEventListener('click', event => {
		event.preventDefault();

		if(!archiveTermFilter) return;

		archiveTermFilter.classList.add('open');
	})

	closeTermFilter && closeTermFilter.addEventListener('click', event => {
		event.preventDefault();

		if(!archiveTermFilter) return;

		archiveTermFilter.classList.remove('open');
	})

	showSalesFilter && showSalesFilter.addEventListener( 'click', event => {
		event.preventDefault();
		if ( !saleFilterWrap ) return;
		saleFilterWrap.classList.add( 'open' );
	} )

	closeSaleFilter && closeSaleFilter.addEventListener( 'click', event => {
		event.preventDefault();
		if ( !saleFilterWrap ) return;

		saleFilterWrap.classList.remove( 'open' );
	} )

	searchInput && searchInput.addEventListener( 'input', debounce( event => {
		event.preventDefault();
		const search = event.target.value;
		const contentWrap = document.querySelector( '.js-search-result' );

		if ( !contentWrap ) return;

		const data = new FormData();
		data.append( 'action', 'ajax_search_product' );
		data.append( 'search', search );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const content = responseData.data.content;

					if ( content ) {
						contentWrap.innerHTML = content;
					} else if ( !content && search ) {
						contentWrap.innerHTML = '<p class="site-header__search-empty">Нічого не знайдено</p>';
					} else {
						contentWrap.innerHTML = '';
					}

				}
			} )

	}, 1000 ) )

	personalDataCancel && personalDataCancel.addEventListener( 'click', event => {
		event.preventDefault();

		if ( !personalDataChange || !personalDataList ) return;

		personalDataList.classList.remove( 'hide' );
		personalDataChange.classList.remove( 'show' );

	} )

	changeUser && changeUser.addEventListener( 'click', event => {
		event.preventDefault();

		if ( !personalDataChange || !personalDataList ) return;

		personalDataList.classList.add( 'hide' );
		personalDataChange.classList.add( 'show' );

	} )

	personalDataForm && personalDataForm.addEventListener( 'submit', event => {
		event.preventDefault();

		const data = new FormData( event.target );
		data.append( 'action', 'ajax_personal_data_change' );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					location.reload();
				}
			} )
	} )

	myReviewsTabLink && myReviewsTabLink.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();

			const link = event.target.getAttribute( 'href' );
			const element = document.querySelector( link );
			const tabItems = document.querySelectorAll( '.js-reviews-tab-item' );

			myReviewsTabLink.forEach( item => {
				item.classList.remove( 'active' )
			} );
			tabItems && tabItems.forEach( item => {
				item.classList.remove( 'active' )
			} );

			if ( element ) {
				element.classList.add( 'active' );
			}
			event.target.classList.add( 'active' );
		} )
	} )

	loadMyQuestion && loadMyQuestion.addEventListener( 'click', event => {
		event.preventDefault();
		const offset = event.target.dataset.offset;
		const totalItems = event.target.dataset.total;

		if ( !offset ) return;

		const data = new FormData();
		data.append( 'action', 'ajax_load_my_question' );
		data.append( 'offset', offset );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const listWrap = document.querySelector( '.js-my-questions-list-wrap' );

					if ( listWrap ) {
						listWrap.innerHTML += responseData.data.content;
					}

					const items = document.querySelectorAll( '.js-my-questions-item' );
					event.target.dataset.offset = parseInt( offset ) + 5;

					if ( items.length >= parseInt( totalItems ) ) {
						event.target.classList.add( 'hide' );
					} else {
						event.target.classList.remove( 'hide' );
					}
				}

			} )
	} )

	loadMyReviews && loadMyReviews.addEventListener( 'click', event => {
		event.preventDefault();
		const offset = event.target.dataset.offset;
		const totalItems = event.target.dataset.total;

		if ( !offset ) return;

		const data = new FormData();
		data.append( 'action', 'ajax_load_my_reviews' );
		data.append( 'offset', offset );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const listWrap = document.querySelector( '.js-my-reviews-list-wrap' );

					if ( listWrap ) {
						listWrap.innerHTML += responseData.data.content;
					}

					const items = document.querySelectorAll( '.js-my-reviews-item' );
					event.target.dataset.offset = parseInt( offset ) + 5;

					if ( items.length >= parseInt( totalItems ) ) {
						event.target.classList.add( 'hide' );
					} else {
						event.target.classList.remove( 'hide' );
					}
				}

			} )
	} )

	deleteComparison && deleteComparison.addEventListener( 'click', event => {
		event.preventDefault();
		const parent = event.target.closest( '.js-сomparison-tab' );
		const checkedCard = parent.querySelectorAll( '.js-card-checked:checked' );
		const termId = event.target.dataset.term;
		const deleteArrIds = [];

		if ( checkedCard ) {
			checkedCard.forEach( item => {
				deleteArrIds.push( item.dataset.id );
			} )
		}

		const data = new FormData();
		data.append( 'action', 'ajax_delete_comparison' );
		data.append( 'term_id', termId );
		data.append( 'product_ids', deleteArrIds );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					deleteArrIds.forEach( item => {
						const element = document.querySelectorAll( `.сomparison-id-${item}` );

						element && element.forEach( item => {
							item.remove();
						} )
					} )
				}
			} )
	} )

	comparisonTabLink && comparisonTabLink.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const link = event.target.getAttribute( 'href' );
			const comparisonTabs = document.querySelectorAll( '.js-сomparison-tab' );
			const activeTab = document.querySelector( link );

			comparisonTabLink.forEach( item => {
				item.classList.remove( 'active' );
			} )

			comparisonTabs.forEach( item => {
				item.classList.remove( 'active' );
			} )

			if ( activeTab ) {
				activeTab.classList.add( 'active' );
			}

			event.target.classList.add( 'active' );
		} )
	} )

	buyAllWish && buyAllWish.addEventListener( 'click', event => {
		event.preventDefault();
		const productCart = document.querySelectorAll( '.js-product-cart' );

		if ( !productCart ) return;
		const productIds = [];

		productCart.forEach( item => {
			productIds.push( item.dataset.id );
		} );

		if ( !productIds ) return;

		const data = new FormData();
		data.append( 'action', 'ajax_buy_all_products' );
		data.append( 'product_ids', productIds );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const totalWishCount = document.querySelector( '.js-wish-total-count' );
					const totalWishPrice = document.querySelector( '.js-wish-total-price' );
					const contentCard = responseData.data.content;
					const totalItems = responseData.data.qty;
					const totalPrice = responseData.data.total;
					const countItems = document.querySelector( '.js-count-card-product' );
					const totalCardPrice = document.querySelector( '.js-total-card-price' );
					const cardList = document.querySelector( '.js-card-list' );
					const checkoutButton = document.querySelector( '.js-checkout-button' );

					if ( totalWishCount ) {
						totalWishCount.innerText = '0'
					}
					if ( totalWishPrice ) {
						totalWishPrice.innerText = '0'
					}

					productCart.forEach( item => {
						item.remove();
					} );

					if ( checkoutButton ) {
						checkoutButton.classList.remove( 'disabled' );
					}

					if ( totalCardPrice ) {
						totalCardPrice.innerHTML = totalPrice;
					}

					if ( countItems ) {
						countItems.innerText = totalItems;
					}

					if ( cardCount ) {
						cardCount.innerText = totalItems;
					}

					if ( cardList ) {
						cardList.innerHTML = contentCard;
					}
				}
			} )

	} )

	deleteWish && deleteWish.addEventListener( 'click', event => {
		event.preventDefault();
		const checkedCard = document.querySelectorAll( '.js-card-checked:checked' );
		const deleteArrIds = [];

		if ( checkedCard ) {
			checkedCard.forEach( item => {
				deleteArrIds.push( item.dataset.id );
			} )
		}

		if ( deleteArrIds ) {
			const data = new FormData();
			data.append( 'action', 'ajax_delete_wish' );
			data.append( 'product_ids', deleteArrIds );

			fetch( var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				} )
				.then( ( response ) => response.json() )
				.then( ( responseData ) => {
					if ( responseData.success ) {
						const totalCount = document.querySelector( '.js-wish-total-count' );
						const totalPrice = document.querySelector( '.js-wish-total-price' );

						checkedCard.forEach( item => {
							item.closest( '.js-product-cart' ).remove();
						} )

						if ( totalPrice ) {
							totalPrice.innerText = responseData.data.total_price;
						}

						if ( totalCount ) {
							totalCount.innerText = responseData.data.count;
						}

					}
				} )
		}

	} )

	cardChecked && cardChecked.forEach( item => {
		item.addEventListener( 'change', event => {
			if ( checkAllWish ) {
				checkAllWish.checked = false;
			}
		} )
	} )

	checkAllWish && checkAllWish.addEventListener( 'change', event => {
		event.preventDefault();
		const productsCheckbox = document.querySelectorAll( '.js-card-checked' );

		if ( event.target.checked ) {
			productsCheckbox && productsCheckbox.forEach( item => {
				const stock = item.dataset.stock;

				if ( stock == 'true' ) {
					item.checked = true;
				}
			} )
		} else {
			productsCheckbox && productsCheckbox.forEach( item => {
				const stock = item.dataset.stock;

				if ( stock == 'true' ) {
					item.checked = false;
				}
			} )
		}
	} )

	sortOrder && sortOrder.addEventListener( 'change', event => {
		event.preventDefault();
		const sortValue = event.target.value;
		const total = event.target.total;
		const listWrap = document.querySelector( '.js-account-order-list' );
		const loadMore = document.querySelector( '.js-account-load-more' );

		if ( !sortValue || !listWrap ) return;

		const data = new FormData();
		data.append( 'action', 'ajax_sort_orders' );
		data.append( 'sort', sortValue );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					listWrap.innerHTML = responseData.data.content;
					const orderItems = document.querySelectorAll( '.js-account-order' );

					if ( loadMore ) {
						loadMore.dataset.offset = '5';
						loadMore.classList.remove( 'hide' );
					}

				}
			} )

	} )

	closeThankYou && closeThankYou.addEventListener( 'click', event => {
		event.preventDefault();
		const parent = event.target.closest( '.js-thank-you-popup' );
		parent.classList.remove( 'show' );
		const currentUrl = new URL( window.location );
		const searchParams = currentUrl.searchParams;
		searchParams.delete( 'thank_you' );
		window.history.pushState( {}, '', currentUrl );
	} )

	checkoutForm && checkoutForm.addEventListener( 'submit', event => {
		event.preventDefault();

		const userName = checkoutForm.querySelector( 'input[name="user_name"]' ).value;
		const userEmail = checkoutForm.querySelector( 'input[name="user_email"]' ).value;
		const userPhone = checkoutForm.querySelector( 'input[name="user_phone"]' ).value;
		const checkoutError = checkoutForm.querySelector( '.js-checkout-error' );

		if ( !userName || !userEmail || !userPhone ) {
			if ( checkoutError ) {
				checkoutError.classList.add( 'show' );
			}

			return;
		}

		if ( checkoutError ) {
			checkoutError.classList.remove( 'show' );
		}

		const data = new FormData( event.target );
		data.append( 'action', 'ajax_add_order' );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					window.location.href = var_from_php.site_url + '?thank_you=true';
				}
			} )

	} )

	checkoutFirstStep && checkoutFirstStep.addEventListener( 'click', event => {
		event.preventDefault();
		if ( !checkoutForm ) return;

		const userName = checkoutForm.querySelector( 'input[name="user_name"]' ).value;
		const userEmail = checkoutForm.querySelector( 'input[name="user_email"]' ).value;
		const userPhone = checkoutForm.querySelector( 'input[name="user_phone"]' ).value;

		if ( !userName || !userEmail || !userPhone ) {
			return;
		}

		const deliveryWrap = document.querySelector( '.js-delivery-wrap' );
		const checkoutButton = document.querySelector( '.js-checkout-button' );

		if ( deliveryWrap ) {
			deliveryWrap.classList.add( 'active' );
		}

		if ( checkoutButton ) {
			checkoutButton.classList.remove( 'disabled' );
		}

	} )

	setSertificate && setSertificate.addEventListener( 'click', event => {
		event.preventDefault();
		const parent = event.target.closest( '.js-set-sertificate-wrap' );
		const sertificateCode = parent.querySelector( 'input[name="certificate_number"]' ).value;
		const messageWrap = document.querySelector( '.js-sertificate-message' );
		const originTotal = document.querySelector( '.js-origin-total' );
		const cardSale = document.querySelector( '.js-card-sale' );

		if ( !sertificateCode ) return;

		const data = new FormData();
		data.append( 'action', 'ajax_set_sale' );
		data.append( 'code', sertificateCode );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const totalCount = document.querySelector( '.js-total-count' );
					if ( totalCount ) {
						totalCount.innerText = responseData.data.new_total;
					}

					if ( originTotal ) {
						originTotal.innerText = responseData.data.original_total;
					}

					if ( cardSale ) {
						cardSale.innerText = responseData.data.sale;
					}

				} else if ( messageWrap ) {
					messageWrap.innerText = responseData.data;
				}

				setTimeout( () => {
					if ( messageWrap ) {
						messageWrap.innerText = '';
					}
				}, 3000 );
			} )

	} )

	telMask && telMask.forEach( item => {
		const im = new Inputmask( "+38 (099) 999 99 99" );
		im.mask( item );
	} )

	returnProductForm && returnProductForm.addEventListener( 'submit', event => {
		event.preventDefault();
		const data = new FormData( event.target );
		data.append( 'action', 'return_product_form' );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					event.target.reset();
				}

				const message = document.querySelector( '.js-return-message' );
				message.innerText = responseData.data;
			} )
	} )

	logout && logout.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const data = new FormData();
			data.append( 'action', 'log_out_user' );

			fetch( var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				} )
				.then( ( response ) => response.json() )
				.then( ( responseData ) => {
					if ( responseData.success ) {
						window.location.href = var_from_php.site_url;
					}
				} )
		} )
	} )

	registerForm && registerForm.addEventListener( 'submit', event => {
		event.preventDefault();

		const data = new FormData( event.target );
		data.append( 'action', 'check_and_register_user' );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					location.reload( true );
				} else {
					const error = document.querySelector( '.js-register-error' );
					error.innerText = responseData.data;
				}
			} )
	} )

	acceptCheckbox && acceptCheckbox.forEach( item => {
		item.addEventListener( 'change', event => {
			event.preventDefault();
			const form = event.target.closest( 'form' );
			const submitButton = form.querySelector( 'input[type="submit"]' );
			if ( !submitButton ) return;

			if ( event.target.checked ) {
				submitButton.disabled = false;
			} else {
				submitButton.disabled = true;
			}
		} )
	} )

	registerNavLinks && registerNavLinks.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const link = event.target.getAttribute( 'href' );
			const contentElem = document.querySelectorAll( '.js-register-content' );

			contentElem.forEach( itemContent => {
				itemContent.classList.remove( 'active' );
			} );

			registerNavLinks.forEach( itemLink => {
				itemLink.classList.remove( 'active' );
			} );
			const element = document.querySelector( `${link}` );
			event.target.classList.add( 'active' );

			if ( element ) {
				element.classList.add( 'active' );
			}

		} )
	} )

	loginForm && loginForm.addEventListener( 'submit', event => {
		event.preventDefault();

		const data = new FormData( event.target );
		data.append( 'action', 'check_and_login_user' );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					location.reload();
				} else {
					const error = document.querySelector( '.js-login-error' );

					if ( error ) {
						error.innerText = responseData.data;
					}
				}

			} )
	} )

	openCard && openCard.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			if ( !cardPopup ) return;
			cardPopup.classList.add( 'open' );
		} )
	})

	closeCard && closeCard.addEventListener( 'click', event => {
		event.preventDefault();
		if ( !cardPopup ) return;
		cardPopup.classList.remove( 'open' );
	} );

	loadQuestion && loadQuestion.addEventListener( 'click', event => {
		event.preventDefault();
		const offset = event.target.dataset.offset;
		const productId = event.target.dataset.id;
		const totalReviews = event.target.dataset.total;

		const data = new FormData();
		data.append( 'action', 'ajax_load_question' );
		data.append( 'offset', offset );
		data.append( 'product_id', productId );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const questionList = document.querySelector( '.js-question-list' );
					if ( !questionList ) return;
					questionList.innerHTML += responseData.data.content;

					const questionItems = document.querySelectorAll( '.js-question-item' );

					event.target.dataset.offset = parseInt( offset ) + 5;

					if ( questionItems.length >= parseInt( totalReviews ) ) {
						event.target.classList.add( 'hide' );
					} else {
						event.target.classList.remove( 'hide' );
					}
				}
			} )

	} )

	openQuestionAnswer && openQuestionAnswer.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const parent = event.target.closest( '.js-question-item' );
			const questionAnswerForm = parent.querySelector( '.js-admin-question-answer' );
			if ( !questionAnswerForm ) return;
			questionAnswerForm.classList.toggle( 'open' );
		} )
	} )

	questionAnswerForm && questionAnswerForm.addEventListener( 'submit', event => {
		event.preventDefault();
		const data = new FormData( event.target );
		data.append( 'action', 'ajax_add_question_answer' );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					location.reload( true );
				}
			} )
	} );

	questionForm && questionForm.addEventListener( 'submit', event => {
		event.preventDefault();
		const data = new FormData( event.target );
		data.append( 'action', 'ajax_add_question' );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					location.reload( true );
				}
			} )

	} )

	addQuestion && addQuestion.addEventListener( 'click', event => {
		event.preventDefault();
		if ( !questionForm ) return;
		questionForm.classList.toggle( 'open' );
	} )

	reviewLinks && reviewLinks.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const link = event.target.getAttribute( 'href' );
			const tabsContent = document.querySelectorAll( '.js-review-tab-content' );
			const content = document.querySelector( `${link}` );

			tabsContent.forEach( item => {
				item.classList.remove( 'active' );
			} )

			reviewLinks.forEach( item => {
				item.classList.remove( 'active' );
			} );

			event.target.classList.add( 'active' );
			if ( content ) {
				content.classList.add( 'active' );
			}

		} );
	} )

	loadReview && loadReview.addEventListener( 'click', event => {
		event.preventDefault();
		const offset = event.target.dataset.offset;
		const productId = event.target.dataset.id;
		const totalReviews = event.target.dataset.total;

		const data = new FormData();
		data.append( 'action', 'ajax_load_review' );
		data.append( 'offset', offset );
		data.append( 'product_id', productId );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const reviewList = document.querySelector( '.js-review-list' );
					if ( !reviewList ) return;
					reviewList.innerHTML += responseData.data.content;

					const reviewItems = document.querySelectorAll( '.js-review-item' );

					event.target.dataset.offset = parseInt( offset ) + 5;

					if ( reviewItems.length >= parseInt( totalReviews ) ) {
						event.target.classList.add( 'hide' );
					} else {
						event.target.classList.remove( 'hide' );
					}
				}
			} )

	} )

	openAnswerForm && openAnswerForm.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const parent = event.target.closest( '.review-item' );
			parent.querySelector( '.js-admin-answer' ).classList.toggle( 'open' );
		} )
	} )

	adminAnswerForm && adminAnswerForm.forEach( itemForm => {
		itemForm.addEventListener( 'submit', event => {
			event.preventDefault();
			const data = new FormData( event.target );
			data.append( 'action', 'ajax_add_review_answer' );

			fetch( var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				} )
				.then( ( response ) => response.json() )
				.then( ( responseData ) => {
					if ( responseData.success ) {
						location.reload( true );
					}
				} )
		} )
	} )

	hideForm && hideForm.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const form = event.target.closest( 'form' );

			if ( form ) {
				form.classList.remove( 'open' );
				form.reset();
			}
		} )
	} )

	showCommentForm && showCommentForm.addEventListener( 'click', event => {
		event.preventDefault();
		if ( ratingForm ) {
			ratingForm.classList.toggle( 'open' );
		}
	} )

	ratingForm && ratingForm.addEventListener( 'submit', event => {
		event.preventDefault();
		const rating = event.target.querySelector( 'input[name="rating"]' ).value;
		const postId = event.target.dataset.post;
		const error = event.target.querySelector( '.error' );
		if ( !rating ) {
			error.innerText = 'Ви не поставили рейтинг';
			return;
		}

		const data = new FormData( event.target );
		data.append( 'action', 'ajax_add_review' );
		data.append( 'product_id', postId );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					location.reload( true );
				}
			} )

	} )

	starLinks && starLinks.forEach( starLink => {
		starLink.addEventListener( 'click', event => {
			event.preventDefault();
			const parent = event.target.closest( '.js-reviews-stars' );
			const rating = event.target.dataset.star;
			parent.querySelector( 'input[name="rating"]' ).value = rating;
			const currentIndex = Array.from( starLinks ).indexOf( starLink );
			const activeStars = document.querySelectorAll( '.js-star-rating.active' );

			activeStars.forEach( item => {
				item.classList.remove( 'active' );
			} );

			for ( let i = 0; i <= currentIndex; i++ ) {
				starLinks[i].classList.add( 'active' );
			}

			starLink.classList.add( 'active' );
		} )
	} )

	if ( sliderProductFor && sliderProductNav ) {
		$( sliderProductFor ).slick( {
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			arrows: false,
			fade: true,
			asNavFor: ".js-single-product-nav",
			responsive: [
				{
					breakpoint: 769,
					settings: {
						dots: true,
					}
				},
			]

		} );
		$( sliderProductNav ).slick( {
			slidesToShow: 4,
			slidesToScroll: 1,
			speed: 500,
			asNavFor: ".js-single-product-for",
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 993,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
			]
		} );
	}

	const salePostRequest = ( offset = 0 ) => {
		const saleFilterForm = document.querySelector( '.js-sale-filter' );
		if ( !saleFilterForm ) return;

		const data = new FormData( saleFilterForm );
		data.append( 'action', 'ajax_get_sale_cart' );
		data.append( 'offset', offset );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: data
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {

				if ( responseData.success ) {
					const sale_list = document.querySelector( '.js-archive-sale-list' );
					const total = responseData.data.total;
					if ( !sale_list ) return;

					if ( offset == 0 ) {
						sale_list.innerHTML = responseData.data.content;
					} else {
						sale_list.innerHTML += responseData.data.content;
					}
					salePagination.dataset.offset = offset + 9;
					const sale_card = document.querySelectorAll( '.js-archive-sale-card' );

					if ( sale_card.length >= total ) {
						salePagination.classList.add( 'hide' )
					} else {
						salePagination.classList.remove( 'hide' )
					}
				}
			} )
	}
	
	salePagination && salePagination.addEventListener( 'click', event => {
		event.preventDefault();
		const offset = parseInt( event.target.dataset.offset );
		salePostRequest( offset );
	} )

	saleFilter && saleFilter.forEach( item => {
		item.addEventListener( 'change', event => {
			event.preventDefault();
			salePostRequest();
		} )
	} )

	const loadArchiveProduct = ( formData, offset, termId ) => {
		formData.append( 'action', 'ajax_load_archive_product' );
		formData.append( 'offset', offset );
		formData.append( 'term_id', termId );
		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: formData
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( !responseData.success ) return;
				const nextPageNumber = responseData.data.next_page;
				const productListWrap = document.querySelector( '.js-product-list' );
				const content = responseData.data.content;
				const totalPages = responseData.data.pages;
				const pagination = document.querySelector( '.js-archive-pagination' );
				const currentPage = document.querySelector( '.js-current-page' );
				const totalPage = document.querySelector( '.js-total-page' );
				const archivePrev = document.querySelector( '.js-archive-prev' );
				const archiveNext = document.querySelector( '.js-archive-next' );
				const noProduct = document.querySelector( '.js-no-product' );
				if ( !content ) {
					noProduct && noProduct.classList.add( 'show' );
				} else {
					noProduct && noProduct.classList.remove( 'show' );
				}

				if ( !productListWrap ) return;
				productListWrap.innerHTML = content;

				if ( !pagination ) return;
				if ( totalPages < 2 ) {
					pagination.classList.add( 'hide' );
				} else {
					pagination.classList.remove( 'hide' );
					currentPage.innerText = parseInt( nextPageNumber ) - 1;
					totalPage.innerText = totalPages;
					archivePrev.dataset.page = parseInt( nextPageNumber ) - 2;
					archiveNext.dataset.page = nextPageNumber;
				}

			} )
	}

	const archiveFormFunction = ( form, offset = 0, screenWidth ) => {
		const termId = form.dataset.term;
		const formData = new FormData( form );
		const queryString = new URLSearchParams( formData ).toString();
		formData.append( 'screen_width', screenWidth);
		const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + queryString;
		history.pushState( {path: newUrl}, '', newUrl );

		loadArchiveProduct( formData, offset, termId );
	}

	const saleFormFunction = ( form, offset = 0 ) => {
		const postId = form.dataset.post;
		const formData = new FormData( form );
		formData.append( 'action', 'ajax_load_sale_product' );
		formData.append( 'offset', offset );
		formData.append( 'post_id', postId );

		fetch( var_from_php.ajax_url,
			{
				method: "POST",
				credentials: 'same-origin',
				body: formData
			} )
			.then( ( response ) => response.json() )
			.then( ( responseData ) => {
				if ( responseData.success ) {
					const nextPageNumber = responseData.data.next_page;
					const saleWrap = document.querySelector( '.js-sale-products-list' );
					const totalPages = responseData.data.pages;
					const pagination = document.querySelector( '.js-sale-pagination' );
					const currentPage = document.querySelector( '.js-current-page' );
					const totalPage = document.querySelector( '.js-total-page' );
					const archivePrev = document.querySelector( '.js-sale-prev' );
					const archiveNext = document.querySelector( '.js-sale-next' );
					if ( !saleWrap ) return;

					if ( !saleWrap || !pagination || !archivePrev || !archiveNext || !totalPage || !currentPage ) return;
					saleWrap.innerHTML = responseData.data.content;

					if ( totalPages < 2 ) {
						pagination.classList.add( 'hide' );
					} else {
						pagination.classList.remove( 'hide' );
						currentPage.innerText = parseInt( nextPageNumber ) - 1;
						totalPage.innerText = totalPages;
						archivePrev.dataset.page = parseInt( nextPageNumber ) - 2;
						archiveNext.dataset.page = nextPageNumber;
					}
				}
			} )
	}

	sortLink && sortLink.forEach( item => {
		item.addEventListener( 'click', event => {
			if ( !archiveForm ) return;
			const screenWidth = window.innerWidth;
			archiveFormFunction( archiveForm, 0, screenWidth);
		} )
	} );

	function toggleSelectDisabled() {
		const screenWidth = window.innerWidth;
		if(archiveMobileSorting && sortLink) {
			if (screenWidth > 992) {
				archiveMobileSorting.setAttribute('disabled', 'disabled');
				sortLink && sortLink.forEach(item => {
					item.removeAttribute('disabled');
				})
			} else {
				archiveMobileSorting.removeAttribute('disabled');
				sortLink && sortLink.forEach(item => {
					item.setAttribute('disabled', 'disabled');
				})
			}
		}

		if(sortSaleLink && saleSortingSelect) {
			if (screenWidth > 768) {
				saleSortingSelect.setAttribute('disabled', 'disabled');
				sortSaleLink && sortSaleLink.forEach(item => {
					item.removeAttribute('disabled');
				})
			} else {
				saleSortingSelect.removeAttribute('disabled');
				sortSaleLink && sortSaleLink.forEach(item => {
					item.setAttribute('disabled', 'disabled');
				})
			}
		}

	}

	toggleSelectDisabled();
	window.addEventListener('resize', toggleSelectDisabled);

	archiveMobileSorting && archiveMobileSorting.addEventListener('change', event => {
		event.preventDefault();
		const screenWidth = window.innerWidth;
		archiveFormFunction( archiveForm, 0, screenWidth);
	})

	sortSaleLink && sortSaleLink.forEach( item => {
		item.addEventListener( 'change', event => {
			if ( !saleFilterForm ) return;
			saleFormFunction( saleFilterForm, 0 );
		} )
	} )

	archiveForm && archiveForm.addEventListener( 'submit', event => {
		event.preventDefault();
		archiveFormFunction( event.target );
	} )

	allProperties && allProperties.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const parent = event.target.closest( '.js-filter-item' );
			parent.querySelector( '.js-hidden-properties' ).classList.toggle( 'open' );
			event.target.classList.toggle( 'open' );
		} );
	} )

	openFilter && openFilter.forEach( item => {
		item.addEventListener( 'click', event => {
			event.preventDefault();
			const parent = event.target.closest( '.js-filter-item' );
			event.target.classList.toggle( 'open' );
			parent.querySelector( '.js-filter-properties' ).classList.toggle( 'open' );
		} )
	} )

	if ( videoReviewsSlider ) {
		$( '.js-video-reviews-slider' ).slick( {
			dots: false,
			arrows: false,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			responsive: [
				{
					breakpoint: 993,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 481,
					settings: {
						centerMode: true,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		} );
	}

	if ( recommendedProductSwiper ) {
		new Swiper( ".js-recommended-product-swiper", {
			slidesPerView: 4,
			slidesPerGroup: 4,
			pagination: {
				el: ".js-recommended-product-dotes",
				type: "fraction",
			},
			navigation: {
				prevEl: ".js-recommended-product-prev",
				nextEl: ".js-recommended-product-next",
			},

			breakpoints: {
				321: {
					slidesPerView: 2,
					slidesPerGroup: 2,
				},

				481: {
					slidesPerView: 3,
					slidesPerGroup: 3,
				},

				769: {
					slidesPerView: 4,
					slidesPerGroup: 4,
				}
			},
		} );
	}

	if ( productCategorySwiper ) {
		new Swiper( ".js-product-category-swiper", {
			slidesPerView: 4,
			slidesPerGroup: 4,
			pagination: {
				el: ".js-product-category-dotes",
				type: "fraction",
			},
			navigation: {
				prevEl: ".js-product-category-prev",
				nextEl: ".js-product-category-next",
			},

			breakpoints: {
				321: {
					slidesPerView: 2,
					slidesPerGroup: 2,
				},

				481: {
					slidesPerView: 3,
					slidesPerGroup: 3,
				},

				769: {
					slidesPerView: 4,
					slidesPerGroup: 4,
				}
			},
		} );
	}

	if ( categoriesSwiper ) {
		new Swiper( ".js-categories-swiper", {
			slidesPerView: "auto",
			spaceBetween: 10,
			freeMode: true,
			navigation: {
				nextEl: ".js-categories-next",
			},
		} );
	}

	openCatalog && openCatalog.addEventListener( 'click', event => {
		event.preventDefault();
		const catalogList = document.querySelector( '.js-catalog-list' );
		event.target.classList.toggle( 'open' );
		catalogList && catalogList.classList.toggle( 'open' );
	} )

	openPhone && openPhone.forEach( item => {
		item.addEventListener( 'click', event => {
			const parent = event.target.closest('.js-phone-wrap')
			const phoneList = parent.querySelector( '.js-phone-list' );
			phoneList && phoneList.classList.toggle( 'open' );
			event.target.classList.toggle( 'open' );
		} )
	})

	if ( bannerSlider ) {
		$( bannerSlider ).slick( {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			autoplay: true,
			autoplaySpeed: 3000,
		} );
	}


	/*scrollTop && scrollTop.addEventListener('click', event => {
		event.preventDefault();
		window.scroll( {
			'behavior': 'smooth',
			'left': 0,
			'top': 0
		} );
	})*/


	/**
	 * Occurs when all HTML has been fully loaded and passed by the parser,
	 * without waiting for the stylesheets, images and frames to finish loading
	 */
	document.addEventListener( "DOMContentLoaded", function ( event ) {
		console.log( "DOM fully loaded and parsed - READY event" );

		function getYouTubeVideoId( url ) {
			const match = url.match( /[?&]v=([a-zA-Z0-9_-]+)/ );
			return match ? match[1] : null;
		}

		// Init Closest polyfill method ( Don't delete it )
		closest_polyfill();

		// Init Popup Windows ( use it if you need Popup functionality )
		const popup_instance = new Popup();
		popup_instance.init();


		/**
		 * Add global handler for click event
		 * The main idea - to improve site performance
		 * We added only 1 event handler to "click" event
		 * and then use date-role attribute on each( needed ) elements
		 * to define on which element event was executed..
		 */
		document.body.addEventListener( 'click', event => {
			const ROLE = event.target.dataset.role;
			const clickElem = event.target;

			if ( !ROLE ) return;

			switch ( ROLE ) {

				// Scroll page to top
				case 'load-term-list': {
					event.preventDefault();
					const productId = clickElem.dataset.id;
					const parent = clickElem.closest( '.js-product-categories-block' )
					const categoryLinks = parent.querySelectorAll( '.js-category-link' );
					categoryLinks && categoryLinks.forEach( item => {
						item.classList.remove( 'active' )
					} );
					clickElem.classList.add( 'active' );
					const data = new FormData();
					data.append( 'action', 'ajax_load_category_product' );
					data.append( 'term_id', productId );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( response ) => {
							if ( response.success ) {
								const sliderContent = response.data.content;
								const termName = response.data.term_name;
								const sliderWrapper = parent.querySelector( '.js-product-category-slider' );
								const sliderTitle = parent.querySelector( '.js-product-categories-title' );
								const empty = response.data.empty
								sliderTitle.innerText = termName;
								if ( !sliderWrapper ) return;

								if ( sliderContent && !empty ) {
									sliderWrapper.innerHTML = sliderContent;
									const productCategorySwiper = document.querySelector( '.js-product-category-swiper' );
									if ( productCategorySwiper ) {
										new Swiper( ".js-product-category-swiper", {
											slidesPerView: 4,
											slidesPerGroup: 1,
											pagination: {
												el: ".js-product-category-dotes",
												type: "fraction",
											},
											navigation: {
												prevEl: ".js-product-category-prev",
												nextEl: ".js-product-category-next",
											},
											breakpoints: {
												320: {
													slidesPerView: 2,
													slidesPerGroup: 2,
												},

												480: {
													slidesPerView: 3,
													slidesPerGroup: 3,
												},

												768: {
													slidesPerView: 4,
													slidesPerGroup: 4,
												}
											},
										} );
									}

								} else {
									sliderWrapper.innerHTML = '<p class="no-product">There are no products in this category</p>';
								}
							}

						} )
				}
					break;
				case 'load-iframe': {
					const videoLink = clickElem.dataset.link;
					const videoId = getYouTubeVideoId( videoLink );
					const parent = clickElem.closest( '.js-video-wrap' );
					parent.classList.add( 'active' );
					parent.innerHTML = `<iframe width="100%" height="auto" src="https://www.youtube.com/embed/${videoId}?feature=oembed&autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
				}
					break;
				case 'next-archive': {
					event.preventDefault();
					const total = clickElem.dataset.total;
					const nextPage = clickElem.dataset.page;
					const offset = (parseInt( nextPage ) * 12) - 12;

					if ( parseInt( nextPage ) <= parseInt( total ) ) {
						if ( !archiveForm ) return;
						archiveFormFunction( archiveForm, offset )
					}

				}
					break;
				case 'prev-archive': {
					event.preventDefault();
					const prevPage = clickElem.dataset.page;

					if ( parseInt( prevPage ) > 0 ) {
						const offset = (parseInt( prevPage ) * 12) - 12;
						if ( !archiveForm ) return;
						archiveFormFunction( archiveForm, offset )
					}
				}
					break;
				case 'next-sale-product': {
					event.preventDefault();
					const total = clickElem.dataset.total;
					const nextPage = clickElem.dataset.page;
					const offset = (parseInt( nextPage ) * 15) - 15;

					if ( parseInt( nextPage ) <= parseInt( total ) ) {
						if ( !saleFilterForm ) return;
						saleFormFunction( saleFilterForm, offset );
					}
				}
					break;
				case 'prev-sale-product': {
					event.preventDefault();
					const prevPage = clickElem.dataset.page;

					if ( parseInt( prevPage ) > 0 ) {
						const offset = (parseInt( prevPage ) * 15) - 15;
						if ( !saleFilterForm ) return;
						saleFormFunction( saleFilterForm, offset );
					}
				}
					break
				case 'like-dislike': {
					event.preventDefault();

					const type = clickElem.dataset.type;
					const user = clickElem.dataset.user;
					const commentId = clickElem.dataset.id;
					const parent = clickElem.closest( '.js-likes-wrap' );

					if ( !user ) return;

					const data = new FormData();
					data.append( 'action', 'ajax_like_dislike_reviews' );
					data.append( 'type', type );
					data.append( 'user_id', user );
					data.append( 'reviews_id', commentId );
					data.append( 'reviews_id', commentId );


					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								parent.querySelector( '.js-like-value' ).innerText = responseData.data.like;
								parent.querySelector( '.js-dislike-value' ).innerText = responseData.data.dislikes;
							}
						} )

				}
					break
				case 'like-dislike-question': {
					event.preventDefault();
					const type = clickElem.dataset.type;
					const user = clickElem.dataset.user;
					const commentId = clickElem.dataset.id;
					const parent = clickElem.closest( '.js-likes-wrap' );

					if ( !user ) return;
					const data = new FormData();
					data.append( 'action', 'ajax_like_dislike_question' );
					data.append( 'type', type );
					data.append( 'user_id', user );
					data.append( 'reviews_id', commentId );
					data.append( 'reviews_id', commentId );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								parent.querySelector( '.js-like-value' ).innerText = responseData.data.like;
								parent.querySelector( '.js-dislike-value' ).innerText = responseData.data.dislikes;
							}
						} )
				}
					break
				case 'change-quantity': {
					event.preventDefault();
					const action = clickElem.dataset.action;
					const parent = clickElem.closest( '.js-card-item' );
					const productQuantity = parent.querySelector( '.js-card-product-quantity' );
					const cardItemTotal = parent.querySelector( '.js-card-item-total' );
					const quantity = productQuantity.innerText;
					const productId = clickElem.dataset.id;
					const countItems = document.querySelector( '.js-count-card-product' );
					const totalCardPrice = document.querySelector( '.js-total-card-price' );
					const totalCardCheckoutPrice = document.querySelector( '.js-total-count' );
					const cardSale = document.querySelector( '.js-card-sale' );
					const originTotal = document.querySelector( '.js-origin-total' );

					if ( action === 'minus' && quantity == '1' ) {
						return;
					}

					const data = new FormData();
					data.append( 'action', 'ajax_change_quantity' );
					data.append( 'type_action', action );
					data.append( 'quantity', quantity );
					data.append( 'product_id', productId );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								const newQuantity = responseData.data.new_quantity;
								const totalItems = responseData.data.total_items;
								const newPrice = responseData.data.new_price;
								const totalPrice = responseData.data.total_price;
								const sale = responseData.data.sale;
								const originalTotal = responseData.data.original_total;

								if ( originTotal ) {
									originTotal.innerText = originalTotal;
								}

								if ( cardSale ) {
									cardSale.innerText = sale;
								}

								if ( cardCount ) {
									cardCount.innerText = totalItems;
								}

								if ( totalCardPrice ) {
									totalCardPrice.innerHTML = totalPrice;
								}

								if ( totalCardCheckoutPrice ) {
									totalCardCheckoutPrice.innerHTML = totalPrice;
								}

								if ( countItems ) {
									countItems.innerText = totalItems;
								}

								if ( productQuantity ) {
									productQuantity.innerText = newQuantity;
								}

								if ( cardItemTotal ) {
									cardItemTotal.innerText = newPrice;
								}
							}
						} )
				}
					break;
				case 'remove-product': {
					event.preventDefault();
					const productId = clickElem.dataset.id;

					const data = new FormData();
					data.append( 'action', 'ajax_remove_product' );
					data.append( 'product_id', productId );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								const cardProduct = clickElem.closest( '.js-card-item' );
								const totalCardPrice = document.querySelector( '.js-total-card-price' );
								const totalCardCheckoutPrice = document.querySelector( '.js-total-count' );
								const countItems = document.querySelector( '.js-count-card-product' );
								const cardSale = document.querySelector( '.js-card-sale' );
								const originTotal = document.querySelector( '.js-origin-total' );
								const checkoutButton = document.querySelector( '.js-checkout-button' );
								cardProduct && cardProduct.remove();

								const cardItem = document.querySelectorAll( '.js-card-item' );

								if ( !cardItem.length ) {
									if ( checkoutButton ) {
										checkoutButton.classList.add( 'disabled' );
									}
								} else {
									if ( checkoutButton ) {
										checkoutButton.classList.remove( 'disabled' );
									}
								}

								if ( originTotal ) {
									originTotal.innerText = responseData.data.original_total;
								}

								if ( cardSale ) {
									cardSale.innerText = responseData.data.sale;
								}

								if ( cardCount ) {
									cardCount.innerText = responseData.data.total_items;
								}

								if ( totalCardPrice ) {
									totalCardPrice.innerHTML = responseData.data.total_price;
								}

								if ( totalCardCheckoutPrice ) {
									totalCardCheckoutPrice.innerHTML = responseData.data.total_price;
								}

								if ( countItems ) {
									countItems.innerText = responseData.data.total_items;
								}


							}
						} )
				}
					break
				case 'add-to-card': {
					event.preventDefault();
					const productId = clickElem.dataset.id;

					if ( !productId ) return;

					const data = new FormData();
					data.append( 'action', 'add_to_cart' );
					data.append( 'product_id', productId );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								const contentCard = responseData.data.content;
								const totalItems = responseData.data.qty;
								const totalPrice = responseData.data.total;
								const countItems = document.querySelector( '.js-count-card-product' );
								const totalCardPrice = document.querySelector( '.js-total-card-price' );
								const cardList = document.querySelector( '.js-card-list' );
								const checkoutButton = document.querySelector( '.js-checkout-button' );

								if ( checkoutButton ) {
									checkoutButton.classList.remove( 'disabled' );
								}

								if ( totalCardPrice ) {
									totalCardPrice.innerHTML = totalPrice;
								}

								if ( countItems ) {
									countItems.innerText = totalItems;
								}

								if ( cardCount ) {
									cardCount.innerText = totalItems;
								}

								if ( cardList ) {
									cardList.innerHTML = contentCard;
								}
							}
						} )
				}
					break;
				case 'add-to-wish-list': {
					event.preventDefault();
					const productId = clickElem.dataset.id;

					const data = new FormData();
					data.append( 'action', 'ajax_wish_list' );
					data.append( 'product_id', productId );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								if ( responseData.data == 'add' ) {
									clickElem.classList.add( 'active' );
								} else {
									clickElem.classList.remove( 'active' );
								}
							}
						} )


				}
					break;
				case 'open-order': {
					event.preventDefault();
					const parent = clickElem.closest( '.js-account-order' );
					if ( !parent ) return;
					const orderContent = parent.querySelector( '.js-account-order-content' );
					if ( orderContent ) {
						$( orderContent ).slideToggle();
					}

					clickElem.classList.toggle( 'open' );

				}
					break
				case 'load-more-orders': {
					event.preventDefault();
					const offset = clickElem.dataset.offset;
					const total = clickElem.dataset.total;
					const listWrap = document.querySelector( '.js-account-order-list' );

					if ( !offset || !total || !listWrap || !sortOrder ) return;

					const data = new FormData();
					data.append( 'action', 'ajax_load_orders' );
					data.append( 'offset', offset );
					data.append( 'sort', sortOrder.value );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								listWrap.innerHTML += responseData.data.content;

								const orderItems = document.querySelectorAll( '.js-account-order' );

								if ( orderItems.length >= parseInt( total ) ) {
									clickElem.classList.add( 'hide' );
								} else {
									clickElem.classList.remove( 'hide' );
								}

								clickElem.dataset.offset = parseInt( offset ) + 5;
							}
						} )

				}
					break
				case 'add-to-сomparison-list': {
					event.preventDefault();
					const productId = clickElem.dataset.id;
					const category = clickElem.dataset.category;

					const data = new FormData();
					data.append( 'action', 'ajax_add_to_сomparison' );
					data.append( 'category', category );
					data.append( 'product_id', productId );

					fetch( var_from_php.ajax_url,
						{
							method: "POST",
							credentials: 'same-origin',
							body: data
						} )
						.then( ( response ) => response.json() )
						.then( ( responseData ) => {
							if ( responseData.success ) {
								if ( responseData.data == 'add' ) {
									clickElem.classList.add( 'active' );
								} else {
									clickElem.classList.remove( 'active' );
								}
							}
						} )
				}
					break
				case 'open-catalog-submenu':  {
					event.preventDefault();
					const parent = clickElem.closest('.js-mobile-catalog-list-item');
					const submenu = parent.querySelector('.js-mobile-catalog-sublist');
					clickElem.classList.toggle('open');

					if(submenu) {
						$(submenu).slideToggle();
					}
				}
				break
			}
		} );

	} );

	function setCookie( cookieName, cookieValue, daysToExpire ) {
		const expirationDate = new Date();
		expirationDate.setTime( expirationDate.getTime() + (daysToExpire * 24 * 60 * 60 * 1000) );

		const expires = "expires=" + expirationDate.toUTCString();
		document.cookie = cookieName + "=" + encodeURIComponent( cookieValue ) + "; " + expires;
	}

	function deleteCookie( cookieName ) {
		// Устанавливаем срок жизни куки в прошлое
		document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	}

	function getCookie( cookieName ) {
		const name = cookieName + "=";
		const decodedCookie = decodeURIComponent( document.cookie );
		const cookieArray = decodedCookie.split( ';' );

		for ( let i = 0; i < cookieArray.length; i++ ) {
			const cookie = cookieArray[i].trim();
			if ( cookie.indexOf( name ) == 0 ) {
				return cookie.substring( name.length, cookie.length );
			}
		}

		return null;
	}


	// Use it when you need to know that everything is loaded (html, styles, images)
	window.addEventListener( 'load', ( event ) => {


	} );

})( jQuery );
